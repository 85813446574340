import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { usePlans } from "../hooks/usePlans"
import { graphql, Link } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import CompareHeader from "../components/compareComponents/CompareHeader"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = usePlans(data.allPlansJson.nodes)

  return (
    <GlobalStyles>
      <MetaDetails
        title="Katso täältä paras puhelinliittymä tarjous 2020 - Tutustu!"
        desc="Täältä nopeasti paras matkapuhelin liittymätarjous. Tarjoukset niin Telialta, DNA:lta, Saunalahdelta kuin Moi Mobiililta. Liittymätarjous ihan jokaiselle!"
      />
      <CompareHeader
        plansHook={plansHook}
        title={"Nappaa paras puhelinliittymä tarjous 2020"}
        pageName={"liittymatarjous"}
        notShowFilters={true}
        slogan="Alapuolelta löydät tämän hetken parhaat liittymätarjoukset niin rajattomista liittymistä kuin myös liittymistä käytön mukaan."
      />
      <AdserviceCompare />
      <Container>
        <Header2>
          Kuinka löytää parhaat tarjoukset ja edulliset puhelinliittymät?
        </Header2>
        <TextArea>
          Lähes jokainen suomalainen käyttää puhelinta, mutta aivan liian harva
          tietää, paljonko maksaa puhelinliittymästään kuukaudessa tai mitä
          kaikkea omaan puhelinliittymään kuuluu.{" "}
          <b>
            Kilpailuttamalla puhelinliittymän voit säästää jopa satoja euroja
            vuodessa
          </b>
          .
        </TextArea>
        <TextArea>
          Mikäli olet vielä epävarma sen suhteen, kannattaisiko juuri sinun
          kilpailuttaa puhelinliittymäsi ja operaattorisi, lue artikkelimme.
          Kerromme sinulle, miten arvioida puhelinliittymän ja
          liittymätarjouksen edullisuutta ja lisäksi käymme läpi muita keskeisiä
          näkökulmia liittymän valitsemiseen liittyen, ja lopuksi mainitsemme
          muutamia edullisia liittymätarjouksia.
        </TextArea>
        <Header3>Mitä kaikkea puhelinliittymään kuuluu?</Header3>
        <Header4>Puheluiden hinnat</Header4>
        <TextArea>
          Kaikkiin matkapuhelinliittymiin sisältyy tänä päivänä puhelut,
          tekstiviestit sekä datakäyttö. Kaikki Suomessa liittymiä tarjoavat
          operaattorit tarjoavat näitä kolmea palvelua. Myös lankapuhelimiin on
          mahdollista saada liittymä, joskin matkapuhelimet ovat nykyään
          yleisempiä ja siksi keskitymme tässä artikkelissa nimenomaan
          matkapuhelinliittymiin. Liittymien puhelujen hinnat voivat vaihdella
          sen mukaan, soittaako puhelimella saman vai eri operaattorin numeroon.
          Valtaosa tänä päivänä tarjottavista liittymistä tarjoaa kuitenkin
          puhelut siten, että kaikki kotimaassa toiseen matkapuhelimeen
          soitettavat puhelut ovat saman hintaisia.
        </TextArea>
        <Header4>Tektiviestien hinnat</Header4>
        <TextArea>
          Tekstiviestit ovat niin ikään osa matkapuhelinoperaattorin tarjoamia
          peruspalveluita. Tekstiviestillä tarkoitetaan tässä artikkelissa
          tavallisia SMS-viestejä. Operaattorit hinnoittelevat tekstiviestit
          useimmiten sen mukaan, että kaikki kotimaan tekstiviestit ovat saman
          hintaisia. Jotkut operaattorit tarjoavat myös sellaisia liittymiä,
          että ulkomaille lähetetyistä tekstiviesteistä ei peritä ylimääräistä
          maksua. Kuten puhelujen kohdalla, myös tekstiviestien hinnoittelu
          kannattaa huomioida eri liittymätarjouksia vertailtaessa. Puhelut ja
          tekstiviestit muodostavat suurimman osan liittymien hinnoista
          Suomessa. Puheluita ja tekstiviestejä tarjotaan liittymissä monella
          erilaisella hinnoittelumallilla, joita kuluttajan kannattaa
          kilpailuttaa.
        </TextArea>
        <Header4>Datan hinta</Header4>
        <TextArea>
          Puheluiden ja tekstiviestien lisäksi datan käyttö eli mahdollisuus
          surffailla netissä omalla puhelimella on monille tärkeässä roolissa.
          Vaikka monella älypuhelimella pääseekin langattoman verkon kautta
          internetiin, puhelinliittymän datapaketti mahdollistaa luotettavamman
          ja mahdollisesti myös nopeamman yhteyden verkkoon. Tällä hetkellä 4G-,
          3G- ja 2G-liittymät ovat käyttäjälle mahdollisia, joista 4G on nopein
          – ja siten myös kallein. 4G:llä on mahdollista myös surffata kahdessa
          hitaammassa verkossa, mutta hitaammalla yhteydellä ei pääse nopeampaan
          verkkoon. Puhelinliittymän hintaan saattaa vaikuttaa myös muun muassa
          seuraavat asiat:
        </TextArea>
        <TextArea>
          <ul>
            <li>Liittymän avausmaksu</li>
            <li>Kuukausimaksu</li>
            <li>MMS-viestien hinta</li>
            <li>Datan käyttö ulkomailla</li>
            <li>Mobiililaajakaistan nopeus</li>
            <li>Pakettiin sisältymättömien puheluiden ja viestien hinnat</li>
          </ul>
        </TextArea>
        <Header3>Jokaiselle sopii erilainen puhelinliittymä</Header3>
        <TextArea>
          Kun sopivaa liittymäratkaisua alkaa kartoittaa, on yleinen erhe
          ajatella, että mitä edullisemmat puhelujen, tekstiviestien ja
          datapaketin hinnat, sitä edullisempi itse liittymäkin on. Koska
          matkapuhelimen käyttäjät käyttävät puhelintaan varsin eri tavoin,
          riippuu liittymän edullisuus ja hyöty pitkälti käyttäjän omista
          puhelintottumuksista. Yksi saattaa puhua puhelimeen kymmeniä tunteja
          kuukaudessa, ja toinen taas käyttää puhelinta paljon nettipelaamiseen.
          Pääasia on, että liittymää hankkiessa tuntee omat tottumuksensa, sillä
          silloin sekä operaattorin että kuluttajan itsensä on monin verroin
          helpompaa luoda
        </TextArea>
        <Header3>Mikä on hyvä liittymätarjous?</Header3>
        <TextArea>
          Hyvä liittymätarjous on sellainen, joka vastaa sinun tarpeitasi ja on
          hinnaltaan edullinen. Liittymätarjouksia vertailtaessa kannattaa siten
          kiinnittää huomiota siihen, kuinka paljon puhelut ja tekstiviestit
          maksavat liittymässä sekä kuinka nopea ja minkä hintainen datapaketti
          liittymään sisältyy. Puheluiden hinnat esitetään tavallisesti
          minuuttipohjaisesti. Tämän lisäksi puheluita voidaan tarjota kiinteään
          kuukausihintaan, johon sisältyy tietty määrä puheluita ja tämän määrän
          ylittävistä puheluista peritään sitten ylimääräinen minuuttipohjainen
          maksu. Tekstiviestien hinnat esitetään samalla tavalla kuin puhelut
          liittymätarjouksissa.
        </TextArea>
        <Header4>Datapaketti edellä</Header4>
        <TextArea>
          Datapaketin hintaa arvioitaessa kannattaa ensin tutkia, kuinka paljon
          surffaa netissä puhelimellaan. Internetin lisäksi puhelimen
          verkkoyhteys mahdollistaa sähköpostin lukemisen, verkkopankin ja
          verkkotunnusten käytön sekä mm. verkkoyhteyttä vaativien pelien
          pelaamisen puhelimella. Mikäli ei käytä internetiä lainkaan tai ei
          muusta syystä halua datapakettia osaksi puhelinliittymäänsä, kannattaa
          valita sellainen liittymä, johon datan käyttöä ei sisälly. Mikäli taas
          nimenomaan haluaa datapaketin puhelimeensa, kannattaa tutkia datan
          käytön nopeutta ja mahdollista käyttörajoitusta. Liittymätarjous voi
          sisältää myös datan rajattoman käytön kiinteään kuukausihintaan.
        </TextArea>
        <Header4>Nopeus on valttia</Header4>
        <TextArea>
          Lähtökohtaisesti suosittelemme lukijoita valitsemaan puhelimeensa
          4G-liittymän, sillä se on kirjoitushetkellä markkinoiden nopein. 3G
          toimii myös hyvin niin Suomessa kuin ulkomaillakin ja se on
          4G-liittymää edullisempi vaihtoehto. Mikäli kuitenkin haluaa pelata
          tai katsoa videoita puhelimellaan, 4G on lähtökohtaisesti paremmin
          toimiva vaihtoehto. Pelit ja videot nimittäin vaativat paljon
          verkkoyhteydeltä, minkä lisäksi ne kuluttavat paljon dataa.
          Datapakettia valitessa suosittelemme lukijoita siis tarkastelemaan,
          miten käyttää puhelintaan ja valitsemaan halvin vaihtoehto sen
          pohjalta.
        </TextArea>
        <Header4>Määräaikainen vai ilman määräaikaa</Header4>
        <TextArea>
          Edellä mainittujen huomioiden lisäksi liittymätarjouksia vertailtaessa
          kannattaa kiinnittää huomiota liittymän muihin ehtoihin. Tyypillinen
          ehto liittymätarjouksissa on määräaikainen sopimus, etenkin silloin,
          kun asiakas ostaa liittymän yhteydessä operaattorilta puhelimen tai
          muun laitteen osana kuukausihintaa. Liittymän määräaikaisuus
          tarkoittaa sitä, että liittymästä tulee maksaa koko määräaikaisuuden
          ajan, ellei pysty purkamaan sopimusta ehtojen mukaisesti ennen
          määräaikaisuuden päättymistä. Tarjouksiin voi sisältyä myös erilaisia
          viihde-etuja tai mahdollisuus ostaa puhelin tai tabletti alennettuun
          hintaan. Liittymätarjouksien edullisuutta kannattaa siten arvioida
          kokonaisvaltaisesti.
        </TextArea>
        <Header3>Miten löytää paras liittymä tarjous?</Header3>
        <TextArea>
          Liittymätarjouksia voi kysyä suoraan omalta tai kilpailevalta
          matkapuhelinoperaattorilta. Operaattoreilla voi olla käynnissä myös
          kampanjoita, jolloin operaattorin myyntiedustajat ottavat yhteyttä
          potentiaalisiin asiakkaisiin esimerkiksi puhelimitse tai juttelemalla
          kasvotusten kauppakeskuksessa tai muulla julkisella paikalla. Kun
          selvittää itselleen halvinta ja parasta liittymätarjousta,
          operaattorin edustaja voi antaa lisätietoa mm. vastaamalla eri
          liittymävaihtoehtoja koskeviin kysymyksiin. Operaattorin
          kivijalkamyymälässä voi päästä myös kokeilemaan liittymätarjoukseen
          kuuluvaa puhelinta etukäteen. Operaattorin edustajaa voi myös pyytää
          lähettämään lisätietoa liittymästä esimerkiksi sähköpostiin.
        </TextArea>
        <Header4>Muista kilpailuttaa</Header4>
        <TextArea>
          Tämän lisäksi erilaiset liittymätarjouksia vertailevat sivustot
          tarjoavat oivan avun sopivaa puhelinliittymää etsittäessä. Tällaiset
          sivustot sisältävät tiedot useammalta eri operaattorilta ja sivustolla
          voit löytää itsellesi parhaimman vaihtoehdon tekemällä omaa käyttöäsi
          koskevat valinnat mm. puheluiden määrään, datapaketin käyttöön
          Suomessa ja ETA-maissa sekä muihin liittymän ehtoihin, kuten
          avausmaksuun tai sopimuksen määräaikaisuuteen liittyen.
          Vertailusivustoa kannattaa hyödyntää, sillä sen avulla ei tarvitse
          tutkia ja selvittää erikseen jokaista eri operaattorin tarjoamaa
          liittymätarjousta tai pakettia.
        </TextArea>
        <TextArea>
          Vertailusivusto on todennäköisesti nopein tapa löytää parhaat
          liittymätarjoukset. Myös eri tarjousten vertailu onnistuu mainiosti,
          ja useimmilla vertailusivustoilla omia toiveita voi muokata
          joustavasti, jotta edullisimman vaihtoehdon löytäminen onnistuisi
          varmasti. Tällainen valintojen ns. "tuunaaminen" kannattaa, sillä
          esimerkiksi datapakettien ehdot ja hinnat eroavat suuresti eri
          liittymien välillä. Parhaimmillaan vertailusivustoa hyödyntäessä
          kilpailutuksen voi tehdä vain muutamassa minuutissa. Sivustojen käyttö
          on myös ilmaista. Liittymän voi useimmiten myös ostaa sivuilta
          suoraan, tosin käyttäjän kannattaa tarkistaa, ettei tästä koidu
          lisäkuluja.
        </TextArea>
        <TextArea>
          Vertailusivuston käyttämisessä suurin heikkous liittyy siihen, ovatko
          sivuston tiedot varmasti ajantasaiset. On siten vertailusivuston
          ylläpidosta kiinni, näkyykö sivustolla varmasti kaikki voimassaolevat
          tarjoukset ja esitetäänkö niiden tiedot oikein. Vertailusivustoa
          käyttäessä ei nimittäin voi saada varmuutta sille asialle, olisiko
          olemassa tarkalleen juuri omien toiveiden mukaista liittymää. Mikäli
          mielessäsi siis on tietynlainen liittymä, jota et vertailusivustolta
          löydä, ota yhteyttä operaattoriin ja kysy mieleisesi palvelun
          saatavuutta heiltä suoraan. Samoin mikäli epäilet sivustolla jonkin
          tiedon paikkaansa pitävyyttä, kannattaa operaattoriin olla yhteydessä.
        </TextArea>
        <Header3>
          Mitä muuta kannattaa huomioida puhelinliittymiä kilpailuttaessa?
        </Header3>
        <TextArea>
          Puhelinliittymiä kilpailuttaessa ja liittymätarjouksia vertailtaessa
          puhelinlaskun loppusumman pienentäminen on monille se tärkein
          kriteeri. Jotta kuitenkin saisit puhelinlaskut pienemmäksi
          kilpailutuksen päätteeksi, sinun tulee tietää, mistä puhelinlaskusi
          koostuvat. Tätä varten kannattaa käydä läpi puhelinlaskun erittelyt
          läpi muutaman eri kuukauden osalta. Erittelystä pääsee näkemään, mikä
          puhelimen käytössä maksaa. Esimerkiksi erilaiset julkisen hallinnon
          numeroiden palvelumaksut eivät sisälly liittymien puheluiden
          kuukausimaksuihin. Mikäli siis soitat usein maksullisiin numeroihin,
          joudut maksamaan nuo samat palvelumaksut liittymästä riippumatta.
        </TextArea>
        <Header4>Surffaa rajattomalla datalla ilman huolia</Header4>
        <TextArea>
          Datapaketin käyttö on toinen asia, mikä usein vaikuttaa puhelinlaskun
          suuruuteen. Rajaton datapaketti on usein runsaasti kännykällä
          surffaavalle se edullisin vaihtoehto. Tällä hetkellä suomalaista
          datapakettia voi käyttää joko ilmaiseksi tai edullisesti muissa
          ETA-maissa. Toukokuussa 2020 nimittäin astui voimaan EU:n alueella
          uudet televiestintäsäännöstöt, jotka asettavat hintakaton sille,
          kuinka paljon operaattorit voivat veloittaa mm. mobiilidatan käytöstä.
          Tämä kannattaa siis huomioida, mikäli matkustaa paljon. Tämän lisäksi
          puhelinliittymä tulisi aina valita luotettavalta operaattorilta:
          <ul>
            <li>
              Operaattorilla tulee olla helposti tavoitettava asiakaspalvelu
            </li>
            <li>
              Operaattorilla tulisi tänä päivänä olla lisäksi toimivat
              verkkopalvelut asiakassuhteiden hoitamiseen
            </li>
            <li>
              Liittymän olisi hyvä toimia myös ulkomailla ja/tai ETA-maiden
              ulkopuolella, jotta lomamatkalla ei tarvitsisi ostaa uutta
              SIM-korttia
            </li>
            <li>
              Operaattorin olisi hyvä olla vakavarainen yritys, jotta se pysyisi
              mukana suomalaisten operaattoreiden hintakilpailussa
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Edellä listattujen kriteerien lisäksi kannattaa huomioida myös
          kokemukset siitä, miten operaattorit huolehtivat olemassa olevista
          asiakkaistaan. Jotkin operaattorit pyrkivät aktiivisesti pitämään
          kiinni asiakkaistaan ja tarjoavat asiakkaalle edullisempaa liittymää,
          mikäli huomaavat voivansa tarjota palvelujaan halvempaan hintaan. Jos
          sinulla siis on hyvä kokemus jostain operaattorista, tämän voi
          huomioida parasta liittymätarjousta haettaessa. Tällä hetkellä eri
          puhelinliittymiä tarjoavien yritysten välillä on nimittäin kova
          hintakilpailu, minkä ansiosta liittymän voi saada operaattorilta kuin
          operaattorilta edulliseen hintaan.
        </TextArea>
        <Header4>Vaihteleeko liittymäsi käyttö?</Header4>
        <TextArea>
          Liittymätarjousten vertailu voi kuitenkin olla haastavaa sellaisessa
          tilanteessa, missä puhelimen käyttö vaihtelee merkittävästi
          kuukaudesta toiseen. Joinain kuukausina voi olla runsas määrä
          soitettuja puheluita ja datapaketin käyttö niin ikään voi vaihdella
          merkittävästi kuukaudesta toiseen. Tällaisessa tilanteessa liittymä,
          joka tarjoaa rajattoman määrän puheluita ja dataa kiinteään
          kuukausihintaan, on usein kuluttajalle se kaikkein edullisin. Mikäli
          kuitenkin haluaa tätä edullisemman liittymän, tulisi puhelimen käyttöä
          rajoittaa siten, ettei puheluiden tai datapaketin käytön määrä nousisi
          kovin korkeaksi.
        </TextArea>
        <Header3>
          Mitä perheellisen kannattaa huomioida puhelinliittymiä vertailtaessa?
        </Header3>
        <TextArea>
          Vielä muutama vuosi sitten puhelinoperaattorit tarjosivat
          perheliittymiä sillä ajatuksella, että saman operaattorin numeroon
          soittaessa puhelut tulisivat edullisemmaksi. Tänä päivänä kuitenkin
          eri operaattoreiden väliset hintaerot ovat sen verran pienet, että
          varsinaisia perhekohtaisia puhelinliittymiä tarjotaan vain vähän.
          Perheellisen ei siten välttämättä kannata keskittää koko perheen
          puhelinliittymiä yhdelle operaattorille. Tämän sijaan parasta
          liittymätarjousta etsiessä kannattaa kiinnittää huomio siihen, miten
          jokainen perheenjäsen käyttää matkapuhelinta tai muuta laitetta ja
          hakea halvimmat liittymät todetun käytön pohjalta.
        </TextArea>
        <Header4>Nettiliittymä myös muihin laitteisiin</Header4>
        <TextArea>
          Monessa perheessä on tänä päivänä matkapuhelimien lisäksi muita
          kannettavia laitteita, kuten tabletteja ja kannettavia tietokoneita.
          Puhelinoperaattorit tarjoavat myös näihin laitteisiin
          mobiililaajakaistan. Esimerkiksi nopealla 4G-yhteydellä tabletilla
          pelaaminen tai etätyöskentely sujuu erittäin mukavasti. Nopea yhteys
          on kätevä myös jakaa tarvittaessa toiseen laitteeseen. Mikäli siis
          perheessä on useampi tabletti, ei jokaiseen laitteeseen välttämättä
          tarvitse hankkia omaa mobiililaajakaistaa. Halvin vaihtoehto onkin
          tarkastella, miten laitteita todellisuudessa käytetään ja tehdä
          ostopäätökset näiden havaintojen pohjalta.
        </TextArea>
        <Header4>Lisäpalvelut liittymään</Header4>
        <TextArea>
          Perheellinen voi hyötyä myös lasten kännykän seurantaan tarkoitetuista
          lisäpalveluista, kuten internetin käytön estosta tai lapsen liikkeiden
          seuraamisesta. Ensimmäinen matkapuhelin saatetaan antaa lapselle tämän
          aloittaessa koulun. Tällöin lapsen kännykän käyttöä ja liikkeitä
          seuraava lisäpalvelu voi olla hyödyksi niin lapselle kuin vanhemmille.
          Lapselle kannattaa antaa myös mahdollisuus soittaa vanhemmilleen
          puhelimellaan, ja tätä tarkoitusta varten tulisi valita lapselle
          sopiva puhelinliittymä. Kaikkein edullisimmissa liittymissä puheluista
          ja tekstiviesteistä maksetaan yksiköittäin, ja valvotussa käytössä
          tällainen liittymä on lapsen käytössä halvin vaihtoehto.
        </TextArea>
        <Header4>Parhaat puhelinliittymä tarjoukset koko perheelle</Header4>
        <TextArea>
          Kokonaisuudessaan perheellisen kannattaa kilpailuttaa
          puhelinliittymät, sillä useamman lapsen perheessä edullisempiin
          liittymiin vaihtamalla voi säästää jopa satoja euroja vuodessa.
          Perheen tulisi kiinnittää huomiota siihen, mitä kaikkia laitteita
          heillä on käytössä ja kuinka paljon niitä todellisuudessa tulee
          käytettyä. Ei välttämättä kannata maksaa kalliista
          mobiililaajakaistasta moneen eri kannettavaan laitteeseen, mikäli
          niitä kaikkia ei päivittäin käytetä. Perheessä tulisi myös kiinnittää
          erityistä huomiota lasten matkapuhelimien ja tablettien käyttöön,
          sillä kustannusten lisäksi kyse on lasten verkkoturvallisuudesta.
        </TextArea>
        <Header3>
          Halvimmat liittymät ja operaattorien parhaat tarjoukset
        </Header3>
        <Header4>Moi tarjoukset</Header4>
        <TextArea>
          Kovan kilpailun vuoksi suomalaisilla operaattoreilla on alati
          voimassaolevia tarjouksia. Tällä hetkellä{" "}
          <Link to="/moi">Moi-operaattori</Link> tarjoaa markkinoiden
          edullisimman liittymän kuuden euron kuukausimaksullaan. Pakettiin
          sisältyy 4GT dataa, jota voi hyödyntää myös ulkomailla 1GT saakka.
          Avausmaksu on 5 euroa ja puheluista veloitetaan 0,055 €/minuutti. Myös
          Elisalta löytyy houkutteleva liittymätarjous, joka kustantaa 7,90
          euroa kuukaudessa. Paketti sisältää rajattomasti dataa, joista 2GT voi
          käyttää ulkomailla. Avausmaksu on 4,90 euroa ja puheluista veloitetaan
          0,069 euroa minuutilta.
        </TextArea>
        <Header4>Telia tarjoukset</Header4>
        <TextArea>
          Jos huomio kiinnitetään edullisen hinnan sijaan liittymän
          ominaisuuksiin ja hinta-laatu -suhteeseen, löytyy{" "}
          <Link to="/telia">Telialta</Link> vertaansa vailla oleva
          liittymätarjous. Vain 23,90 euron kuukausihintaan kuluttaja saa
          rajattomasti puheaikaa, rajattomasti dataa nopealla 200Mbit/s
          yhteydellä sekä ilmaiset teksti- ja MMS -viestit. Lisäksi datan käyttö
          EU-maissa ja Baltiassa sisältyvät paketin hintaan. Avausmaksu on 3
          euroa.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { offer: { eq: true } }, sort: { fields: price }) {
      nodes {
        id
        benefits
        callPrice
        internet
        internetSpeed
        name
        price
        type
        url
        textPrice
        img
        openFee
      }
    }
  }
`

export default FrontPage
